import { useUserToken } from "@igloo-be-omnipartners/hooks";
import { defaultLocale } from "@royalcanin-fr-loyalty/settings";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  LoyaltyRetrieveTransactionHistory,
  LoyaltyRetrieveTransactionHistoryVariables,
} from "./__generated__/LoyaltyRetrieveTransactionHistory";
import {
  LoyaltyTransactionHistoryPetSales,
  LoyaltyTransactionHistoryPetSalesVariables,
} from "./__generated__/LoyaltyTransactionHistoryPetSales";

const LoyaltyRetrieveTransactionHistoryQuery = gql`
  query LoyaltyRetrieveTransactionHistory(
    $token: String!
    $page: Float
    $limit: Float
    $sort: String
    $locale: String!
  ) {
    loyaltyRetrieveTransactionHistory(
      token: $token
      page: $page
      limit: $limit
      sort: $sort
    ) {
      pageInfo {
        page
        count
        limit
        hasNextPage
      }
      results {
        action
        total_points
        transaction_date
        label(locale: $locale)
      }
    }
  }
`;

export const useLoyaltyRetrieveTransactionHistory = ({
  page,
  limit,
  sort,
}: {
  page?: number;
  limit?: number;
  sort?:
    | "transaction_date ASC"
    | "transaction_date DESC"
    | "transaction_id ASC"
    | "transaction_id DESC";
} = {}) => {
  const token = useUserToken();
  const res = useQuery<
    LoyaltyRetrieveTransactionHistory,
    LoyaltyRetrieveTransactionHistoryVariables
  >(LoyaltyRetrieveTransactionHistoryQuery, {
    skip: !token,
    variables: {
      token,
      page,
      sort,
      limit: limit || 20,
      locale: defaultLocale,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageInfo = (res.data &&
    res.data.loyaltyRetrieveTransactionHistory &&
    res.data.loyaltyRetrieveTransactionHistory.pageInfo) || {
    page: 1,
    hasNextPage: true,
  };

  return {
    ...res,
    pageInfo,
    data:
      (res.data &&
        res.data.loyaltyRetrieveTransactionHistory &&
        res.data.loyaltyRetrieveTransactionHistory.results) ||
      [],
    onLoadMore: async () => {
      if (!pageInfo.hasNextPage || res.loading) {
        return Promise.resolve();
      }

      return res.fetchMore({
        variables: {
          page: pageInfo.page + 1,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            loyaltyRetrieveTransactionHistory: {
              ...prev.loyaltyRetrieveTransactionHistory,
              results: [
                ...prev.loyaltyRetrieveTransactionHistory.results,
                ...fetchMoreResult.loyaltyRetrieveTransactionHistory.results,
              ],
              pageInfo:
                fetchMoreResult.loyaltyRetrieveTransactionHistory.pageInfo,
            },
          };
        },
      });
    },
  };
};

const LoyaltyTransactionHistoryPetSalesQuery = gql`
  query LoyaltyTransactionHistoryPetSales($token: String!) {
    loyaltyTransactionHistoryPetSales(token: $token) {
      total
      currentYear
      currentMonth
    }
  }
`;

export const useLoyaltyTransactionHistoryPetSales = ({
  skip,
}: {
  skip?: boolean;
}) => {
  const token = useUserToken();
  const res = useQuery<
    LoyaltyTransactionHistoryPetSales,
    LoyaltyTransactionHistoryPetSalesVariables
  >(LoyaltyTransactionHistoryPetSalesQuery, {
    skip: !token || skip,
    variables: {
      token,
    },
  });

  return {
    ...res,
    data: res.data?.loyaltyTransactionHistoryPetSales,
  };
};
