import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  LoyaltyGetPointsExpirationDate,
  LoyaltyGetPointsExpirationDateVariables,
} from "./__generated__/LoyaltyGetPointsExpirationDate";

const LoyaltyGetPointsExpirationDateQuery = gql`
  query LoyaltyGetPointsExpirationDate($token: String!) {
    loyaltyGetPointsExpirationDate(token: $token) {
      expiration_date
      expiration_points
      data {
        expiration_date
        expiration_points
      }
    }
  }
`;

export const useLoyaltyGetPointsExpirationDate = () => {
  const token = useUserToken();
  const res = useQuery<
    LoyaltyGetPointsExpirationDate,
    LoyaltyGetPointsExpirationDateVariables
  >(LoyaltyGetPointsExpirationDateQuery, {
    skip: !token,
    variables: {
      token,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loyaltyGetPointsExpirationDate =
    res.data && res.data.loyaltyGetPointsExpirationDate;

  return {
    ...res,
    data: loyaltyGetPointsExpirationDate,
  };
};
