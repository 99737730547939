import { CircularProgress } from "@material-ui/core";
import { PointsHeader, PointsHistory } from "@royalcanin-fr-loyalty/ui-kit";
import { NetworkStatus } from "apollo-client";
import React from "react";
import styled from "styled-components";
import { Layout } from "../components/Layout";
import { useLoyaltyGetPointsExpirationDate } from "../lib/hooks/useLoyaltyGetPointsExpirationDate";
import { useLoyaltyRetrieveBalance } from "../lib/hooks/useLoyaltyRetrieveBalance";
import { useLoyaltyRetrieveTransactionHistory } from "../lib/hooks/useLoyaltyRetrieveTransactionHistory";
import { withPage } from "../lib/withPage";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

const Loader = () => (
  <LoaderContainer>
    <CircularProgress />
  </LoaderContainer>
);

const Points = () => {
  const {
    data: currentPoints,
    loading: balanceLoading,
  } = useLoyaltyRetrieveBalance();
  const {
    data: items,
    onLoadMore,
    loading: loadingHistory,
    pageInfo,
    networkStatus,
  } = useLoyaltyRetrieveTransactionHistory({ sort: "transaction_date DESC" });
  const { data: expiresPoints } = useLoyaltyGetPointsExpirationDate();
  // const { data: currentRole } = useUserRole();
  // const {
  //   data: dataPetSales,
  //   loading: loadingPetSales,
  // } = useLoyaltyTransactionHistoryPetSales({
  //   skip: !currentRole?.roleDefinition.counterPetsales,
  // });

  return (
    <Layout noPaddingTop>
      <PointsHeader
        currentPoints={balanceLoading ? "-" : currentPoints}
        nextExpiresDate={expiresPoints && expiresPoints.expiration_date}
        nextExpiresPoints={expiresPoints && expiresPoints.expiration_points}
        expireData={(expiresPoints?.data || []).map((item) => ({
          points: item.expiration_points,
          date: item.expiration_date,
        }))}
      />

      {/* {!!currentRole?.roleDefinition.counterPetsales && (
        <Petsales {...dataPetSales} loading={loadingPetSales} />
      )} */}

      {loadingHistory && items.length === 0 ? (
        <Loader />
      ) : (
        <PointsHistory
          points={(items || []).map((item) => ({
            ...item,
            transaction_date: new Date(item.transaction_date),
          }))}
          buttonMoreProps={{
            disabled: networkStatus === NetworkStatus.fetchMore,
            onClick: () => onLoadMore(),
            hidden: !pageInfo.hasNextPage,
          }}
        />
      )}
    </Layout>
  );
};

export default withPage(Points);
